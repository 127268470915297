<template>
    <div class="schedaStabilimento" style="height:100%;">
        <v-layout justify-center align-center style="height:100%;">
            <v-flex xs11 md8 lg5>
                <v-toolbar color="secondary">
                    <v-toolbar-title class="white--text">
                        Scheda Stabilimento
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="remove_documento()" color="white">
                        <v-icon small>fas fa-trash</v-icon>
                    </v-btn>
                    <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                        <v-icon small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card outlined>
                    <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                        <v-container>
                            <v-layout column>
                                <v-row>
                                    <v-col cols="9">
                                        <v-text-field :readonly="!isEditing"
                                                      label="Indirizzo"
                                                      v-model="indirizzo"
                                                      required
                                                      :rules="requiredRules"/>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn @click="openOnMaps()" class='mb-3 mt-3'>
                                            <v-icon small class="pr-2">fas fa-map</v-icon> Naviga
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-select :disabled="!isEditing"
                                          :items="['Semestrale', 'Annuale']"
                                          v-model="frequenza_controlli"
                                          label="Frequenza Controlli"
                                          hide-details=""
                                          class="mb-3"
                                          required
                                          :rules="requiredRules"
                                          outlined/>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" class="pt-0">
                                            <v-file-input :disabled="!isEditing"
                                                          accept="image/jpeg,image/png,image/bmp,image/jpg,image/*"
                                                          label="Documento"
                                                          v-model="mappa"
                                                          :rules="requiredRules"
                                                          prepend-inner-icon="fas fa-map"
                                                          prepend-icon=""
                                                          @change="documentChange = true"
                                                          truncate-length="25" />
                                        </v-col>
                                        <!--v-col cols="3">
                                          <v-btn
                                              color="primary"
                                              width="100%"
                                              :to="'/infoStabilimento?_id='+_id"
                                          >VEDI</v-btn>
                                        </v-col-->
                                    </v-row>
                                </v-container>
                                <v-textarea :readonly="!isEditing"
                                            label="Note"
                                            v-model="note"
                                            outlined
                                            auto-grow
                                            rows="1"/>
                                <v-text-field readonly
                                              label="Cliente"
                                              v-model="cliente"/>
                                <v-combobox v-model="reparti"
                                            :readonly="!isEditing"
                                            :hide-no-data="false"
                                            hide-selected
                                            label="Reparti"
                                            multiple
                                            required
                                            persistent-hint
                                            small-chips />
                            </v-layout>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="5" sm="5" md="3" offset-md="2" class="pt-0">
                                    <v-btn color="primary" width="100%" :to="'/infoStabilimento?_id='+_id">VEDI INFO</v-btn>
                                </v-col>
                                <v-col cols="5" sm="5" md="3" offset="2" offset-md="2" class="pt-0">
                                    <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import apiStabilimenti from '@/js/pages/stabilimenti';
import { requiredRules } from '@/js/validationRules';

export default {
    name: 'schedaStabilimento',
    data() {
        return {
            token: this.$cookies.get('token'),
            _id: this.$route.query._id,
            isEditing: false,
            indirizzo: '',
            frequenza_controlli: '',
            note: '',
            cliente: undefined,
            mappa: undefined,
            formValid: false,
            requiredRules: requiredRules,
            loading: false,
            documentChange: false,
            lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
            reparti: [],
        }
    },
    async created() {
        if(!this._id) this._id = this.$route.query._id;
        let stabilimento = await apiStabilimenti.getOne(this._id);
        this.indirizzo = stabilimento.indirizzo || '';
        this.frequenza_controlli = stabilimento.frequenza_controlli || '';
        this.note = stabilimento.note || '';
        this.cliente = stabilimento.cliente.nominativo1 || '';
        let reparti = [];
        if (stabilimento.reparti) {
            for (let reparto of stabilimento.reparti) {
                if (reparto.nome)
                    reparti.push(reparto.nome)
            }
        }
        this.reparti = reparti;

        let nomeFile = '';
        if(stabilimento.file){
            nomeFile = stabilimento.file.substring(stabilimento.file.lastIndexOf("/")+1);
            nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
        }
        this.mappa = {
            name: nomeFile,
            size: 10
        };
    },
    methods: {
        async submit(){
            if(this.isEditing){
                this.loading = true;
                if(
                    this.$refs.form_data.validate() &&
                    this.formValid &&
                    await this.sendFile()
                ){
                    let data = {
                        _id: this._id,
                        indirizzo: this.indirizzo,
                        frequenza_controlli: this.frequenza_controlli,
                        note: this.note,
                        reparti: this.reparti
                    };
                    await apiStabilimenti.updateOne(this.token, data);
                    this.$router.back();
                }
                this.loading = false;
            } else this.$router.back();
        },
        async remove_documento(){
            if(confirm("Eliminare lo stabilimento?")){
                let data = { _id: this._id };
                await apiStabilimenti.deleteOne(this.token, data);
                this.$router.back();
            }
        },
        async sendFile() {
            if(this.documentChange){
                if(this.mappa) {
                    let formData = new FormData();
                    formData.append("mappa", this.mappa);
                    return await apiStabilimenti.sendFile(this.token, this._id, formData);
                } else return false;
            } else return true;
        },
        openOnMaps() {
            window.open('https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(this.indirizzo))
        }
    },
}
</script>
